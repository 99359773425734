import React from 'react'
import cx from 'classnames'
import { HeadingMd } from '../heading/Heading'
import { ButtonTertiary, ButtonSecondary } from '../../components/button/Button'
import { ContainerMd } from '../container/Container'
import * as styles from './CtaButtons.module.css'

export function CtaButtons() {
  return (
    <div className={styles.component}>
      <ContainerMd>
        <div className={styles.layout}>
          <div className={styles.heading}>
            <HeadingMd h={2}>Neem contact op</HeadingMd>
          </div>
          <div className={styles.buttons}>
            <ButtonTertiary target="_blank" url="tel:+31642017721">
              <span className={cx('material-icons')}>call</span>
              +31 (0)6 42017721
            </ButtonTertiary>
            <span>of</span>
            <ButtonSecondary target="_blank" url="mailto:info@empowermom.nl">
              <span className={cx('material-icons')}>email</span>
              stuur een email
            </ButtonSecondary>
          </div>
        </div>
      </ContainerMd>
    </div>
  )
}
