import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { HeadingMd } from '../components/heading/Heading'
import { Layout } from '../components/layout/Layout'
import { Hero } from '../components/hero/Hero'
import { CtaButtons } from '../components/cta-buttons/CtaButtons'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Cards } from '../components/cards/Cards'

const IndexPage = () => {
  return (
    <Layout title="Homepage" desc="Empowermom is een innovatieve, outreachende en laagdrempelige organisatie die zich onderscheid door betrokken te zijn in de thuis-/privésituatie van de (aanstaande) moeder heeft als missie om (aanstaande) moeders in hun kracht te zetten in - misschien wel de kwetsbaarste fase van - hun leven, de transitie/het avontuur van moederschap.">

      <Hero
        image={
          <StaticImage
            src="../images/pexels-kseniya-mazaeva-8233555.jpg"
            placeholder="blurred"
            alt=""
            loading="eager"
            formats={['auto', 'webp', 'avif']}
          />
        }
        title="Moeder zijn en van je kind houden is makkelijk, het moederschap is een uitdaging!"
        button={{ title: 'Informatie over het traject', url: '/traject' }}
      />

      <TextBanner>
        <HeadingMd h={2}>Onze visie</HeadingMd>
        <p>Gun jij jezelf en je kind een kansrijke start? Ken jij de zin ‘it takes a village to raise a child’?</p>
        <p>Uit verschillende onderzoeken blijkt dat er relatief weinig tijd wordt besteed aan de transitie naar het moederschap en de balans in het nieuwe of uitgebreide gezin terwijl moeders hier wel behoefte aan hebben. Ook blijkt dat 1 op de 10 vrouwen last heeft van psychische klachten tijdens of na de zwangerschap. Op het bespreekbaar maken van kwetsbaarheden, zorgen en klachten rust nog steeds een behoorlijk taboe. Vrouwen hebben vaak een ‘plaatje’ in hun hoofd waar het moederschap aan moet voldoen en voelen alsof zij gefaald hebben wanneer zij niet aan dat plaatje voldoen. Vervolgens ontstaan er schuldgevoelens en schaamte waardoor zij steeds minder in hun kracht staan wat kan leiden tot een negatieve invloed op het welzijn van de moeder, het gezin en de ontwikkeling van het kind.</p>
        <p>Empowermom is een innovatieve, outreachende en laagdrempelige organisatie die zich onderscheidt door betrokken te zijn in de thuis-/privésituatie van de (aanstaande) moeder en heeft als missie om (aanstaande) moeders in hun kracht te zetten in – misschien wel de kwetsbaarste fase van - hun leven, de transitie naar het moederschap.</p>
        <p>De liefde en het besef van verantwoordelijk zijn voor je kind heeft iedere moeder. Empowermom staat voor eerlijk moederschap, de minder leuke momenten mogen er ook zijn. Het taboe doorbreken over het ontbreken van de roze wolk door je gevoelens en gedachten bespreekbaar te maken met de postpartum coach over onderwerpen zoals:</p>
        <ul>
          <li>Verwachting tegenover de realiteit van je zwangerschap</li>
          <li>Verloop van je (spoed-)bevalling</li>
          <li>Leren leven met je lichaam in de zwangerschap en na de bevalling</li>
          <li>Navigeren van en omgaan met je hormonen</li>
          <li>Opereren in en functioneren met weinig slaap</li>
          <li>Verantwoordelijkheidsgevoel over de veiligheid en het welzijn van je kind</li>
          <li>Samenwerking en het contact met je partner</li>
          <li>Onderhouden van je relaties en vriendschappen</li>
          <li>Achterhalen/ontdekken wie je bent als moeder, een nieuwe rol</li>
          <li>Invulling van je werk</li>
          <li>Omgaan met je (schuld)gevoelens</li>
          <li>Peinzen over ouderschapsbesluiten of misstappen</li>
          <li>Gevoelens van eenzaamheid</li>
        </ul>
        <p>Vanuit de visie ‘van verwijt naar verwachting tot vaardigheid’ wordt er gewerkt aan krachtig moederschap en doorbreken we het taboe over het ontbreken van de “de roze wolk”.</p>
      </TextBanner>

      <CtaButtons />

      <Cards />
    </Layout>
  )
}

export default IndexPage
