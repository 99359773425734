import React from 'react'
import { HeadingSm } from '../heading/Heading'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import * as styles from './Cards.module.css'

export function Cards() {
  return (
    <section className={styles.cards}>
      <div className={styles.layout}>
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/pexels-pixabay-356079.jpg"
              alt="Meestgestelde vragen"
            />
          }
          title="Meestgestelde vragen"
          url="/meestgestelde-vragen"
        />
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/pexels-tobi-631986.jpg"
              alt=""
            />
          }
          title="Het traject"
          url="/traject"
        />
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/pexels-karolina-grabowska-5398881.jpg"
              alt=""
            />
          }
          title="De postpartum coach"
          url="/coach"
        />
      </div>
    </section>
  )
}

function Card({ image, title, url }) {
  return (
    <div to={url} className={styles.card}>
      <div className={styles.image}>{image}</div>
      <div className={styles.heading}>
        <HeadingSm>{title}</HeadingSm>
      </div>
      <div className={styles.link}>
        <Link to={url}></Link>
      </div>
    </div>
  )
}
